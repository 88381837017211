var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { staticClass: "mb-4", attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "auto" } },
                [
                  _c("b-avatar", {
                    attrs: { size: "85px", src: _vm.patientInfo.profileUrl },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _vm.isEditing
                    ? _c("div", { staticClass: "mb-4" }, [
                        _c(
                          "div",
                          [
                            _c("p", { staticClass: "text-muted mb-2" }, [
                              _c("small", [_vm._v("First Name")]),
                            ]),
                            _c("b-form-input", {
                              model: {
                                value: _vm.firstname,
                                callback: function ($$v) {
                                  _vm.firstname = $$v
                                },
                                expression: "firstname",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("p", { staticClass: "text-muted mb-2" }, [
                              _c("small", [_vm._v("Last Name")]),
                            ]),
                            _c("b-form-input", {
                              model: {
                                value: _vm.lastname,
                                callback: function ($$v) {
                                  _vm.lastname = $$v
                                },
                                expression: "lastname",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _c("h3", { staticClass: "bold" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${_vm.patientInfo.firstname} ${_vm.patientInfo.lastname}` ||
                                "Unnamed"
                            ) +
                            " "
                        ),
                      ]),
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center",
                      attrs: {
                        pill: "",
                        size: "sm",
                        variant: "outline-dark",
                        block: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:showNotes", true)
                        },
                      },
                    },
                    [
                      _c("v-icon", {
                        attrs: {
                          name: "note",
                          variant: "",
                          size: "20",
                          color: "currentColor",
                        },
                      }),
                      _c("span", { staticClass: "d-inline-block ml-2" }, [
                        _vm._v(" Notes "),
                      ]),
                      _vm.patientInfo.medicalnotescount > 0
                        ? _c(
                            "div",
                            { staticClass: "tw-text-sm tw-ml-1" },
                            [
                              _c(
                                "b-badge",
                                {
                                  staticClass: "tw-text-white",
                                  attrs: { pill: "" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.patientInfo.medicalnotescount
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  !_vm.currentDoctor.id
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center",
                          attrs: {
                            pill: "",
                            size: "sm",
                            variant: "outline-dark",
                            block: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.show("setReminder")
                            },
                          },
                        },
                        [
                          _c("v-icon", {
                            attrs: {
                              name: "reminder",
                              color: "currentColor",
                              variant: "",
                            },
                          }),
                          _c("span", [_vm._v("Set Reminder")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-col", { staticClass: "mb-4", attrs: { cols: "12" } }, [
        !_vm.isViewingContactInfo
          ? _c(
              "a",
              {
                staticClass:
                  "tw-uppercase hover:tw-underline tw-text-xs tw-tracking-wider tw-cursor-pointer",
                on: {
                  click: function ($event) {
                    _vm.isViewingContactInfo = true
                  },
                },
              },
              [_vm._v(" Contact Info & Address ")]
            )
          : _c(
              "a",
              {
                staticClass:
                  "tw-uppercase hover:tw-underline tw-text-xs tw-tracking-wider tw-cursor-pointer tw-inline-flex tw-items-center",
                on: {
                  click: function ($event) {
                    _vm.isViewingContactInfo = false
                  },
                },
              },
              [
                _c(
                  "svg",
                  {
                    staticStyle: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      "stroke-linejoin": "round",
                      "stroke-miterlimit": "2",
                    },
                    attrs: {
                      width: "16px",
                      height: "8px",
                      viewBox: "0 0 16 8",
                    },
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1,0,0,1,-1232.66,-1750.33)",
                        },
                      },
                      [
                        _c("path", {
                          staticStyle: { fill: "rgb(255, 141, 64)" },
                          attrs: {
                            d: "M1232.66,1753.77L1232.66,1753.77L1232.66,1754.97C1234.55,1754.97 1235.52,1756.6 1235.52,1757.54L1236.72,1757.54C1236.72,1756.72 1236.23,1755.51 1235.24,1754.68L1247.66,1754.68L1247.66,1753.48L1235.11,1753.48C1236.18,1752.57 1236.72,1751.18 1236.72,1750.33L1235.52,1750.33C1235.52,1751.29 1234.57,1753.19 1232.66,1753.19L1232.66,1753.77Z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "span",
                  { staticClass: "tw-inline-block ml-1 tw-text-inherit" },
                  [_vm._v("Back")]
                ),
              ]
            ),
      ]),
      !_vm.isViewingContactInfo
        ? [
            _c(
              "b-col",
              { staticClass: "mb-3", attrs: { cols: "12" } },
              [
                _c(
                  "b-row",
                  { attrs: { "align-v": "start" } },
                  [
                    _c(
                      "b-col",
                      { staticClass: "mb-3", attrs: { cols: "6" } },
                      [
                        _c("p", { staticClass: "text-muted mb-2" }, [
                          _c("small", [_vm._v("Date of Birth")]),
                        ]),
                        _vm.isEditing
                          ? _c("b-form-datepicker", {
                              attrs: {
                                id: "birthdate",
                                "date-format-options": {
                                  year: "numeric",
                                  month: "long",
                                  day: "2-digit",
                                },
                                "hide-header": true,
                                dark: _vm.isDarkMode,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return undefined
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1109824379
                              ),
                              model: {
                                value: _vm.birthday,
                                callback: function ($$v) {
                                  _vm.birthday = $$v
                                },
                                expression: "birthday",
                              },
                            })
                          : _c("h4", { staticClass: "text-black" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .moment(_vm.patientInfo.birthday)
                                      .format("MMMM D, YYYY") ||
                                      "No Information Available"
                                  ) +
                                  " "
                              ),
                            ]),
                      ],
                      1
                    ),
                    _c("b-col", { attrs: { cols: "3" } }, [
                      _c("p", { staticClass: "text-muted mb-2" }, [
                        _c("small", [_vm._v("Age")]),
                      ]),
                      _c("h4", { staticClass: "text-black" }, [
                        _vm._v(_vm._s(_vm.patientInfo.age)),
                      ]),
                    ]),
                    _c(
                      "b-col",
                      {
                        style: { "padding-left": _vm.isEditing ? "0" : "12px" },
                        attrs: { cols: "3" },
                      },
                      [
                        _c("p", { staticClass: "text-muted mb-2" }, [
                          _c("small", [_vm._v("State")]),
                        ]),
                        _c("h4", { staticClass: "text-black" }, [
                          _vm._v(_vm._s(_vm.patientInfo.state)),
                        ]),
                      ]
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.patientInfo.unselectedproduct != undefined &&
                        _vm.patientInfo.unselectedproduct.length > 0
                          ? [
                              _c("p", { staticClass: "text-muted mb-2" }, [
                                _c("small", [
                                  _vm._v("Declined onboarding treatment"),
                                ]),
                              ]),
                              _vm._l(
                                _vm.patientInfo.unselectedproduct,
                                function (plan, i) {
                                  return _c(
                                    "h4",
                                    { key: i, staticClass: "text-black" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(plan.name || "") + " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm.patientInfo.followupdatestamp
                      ? _c(
                          "b-col",
                          { staticClass: "mb-3", attrs: { cols: "12" } },
                          [
                            _c("p", { staticClass: "text-muted mb-2" }, [
                              _c("small", [_vm._v("Follow-up Date")]),
                            ]),
                            _c("h4", { staticClass: "text-black" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.parseDate(
                                      _vm.patientInfo.followupdatestamp,
                                      "MMMM d, yyyy"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      { staticClass: "mb-3", attrs: { cols: "12" } },
                      [
                        _c("p", { staticClass: "text-muted mb-2" }, [
                          _c("small", [_vm._v("Treatment requested")]),
                        ]),
                        _vm.patientInfo.desiredplan
                          ? _vm._l(
                              _vm.patientInfo.desiredplan,
                              function (plan, i) {
                                return _c(
                                  "h4",
                                  { key: i, staticClass: "text-black" },
                                  [_vm._v(" " + _vm._s(plan.name || "") + " ")]
                                )
                              }
                            )
                          : _vm.patientInfo.preferredtreatment
                          ? [
                              _c("h4", { staticClass: "text-black" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.patientInfo.preferredtreatment || ""
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          : [
                              _c("h4", { staticClass: "text-black" }, [
                                _vm._v("Let doctor choose medication"),
                              ]),
                            ],
                      ],
                      2
                    ),
                    _vm.patientInfo.upsell.length > 0
                      ? _c(
                          "b-col",
                          { staticClass: "mb-3", attrs: { cols: "12" } },
                          [
                            _c("p", { staticClass: "text-muted mb-2" }, [
                              _c("small", [_vm._v("Prescription Request")]),
                            ]),
                            _vm._l(
                              _vm.patientInfo.upsell,
                              function (upsell, i) {
                                return _c(
                                  "h4",
                                  { key: i, staticClass: "text-black" },
                                  [
                                    _vm._v(" " + _vm._s(upsell.name) + " "),
                                    upsell.subscriptiontype
                                      ? _c("span", [
                                          _vm._v(
                                            " (" +
                                              _vm._s(upsell.subscriptiontype) +
                                              ") "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.patientInfo.preferredshipmentname
                      ? _c(
                          "b-col",
                          { staticClass: "mb-3", attrs: { cols: "12" } },
                          [
                            _c("p", { staticClass: "text-muted mb-2" }, [
                              _c("small", [_vm._v("Preferred Shipment")]),
                            ]),
                            _c("h4", { staticClass: "text-black" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.patientInfo.preferredshipmentname ||
                                      "No Information Available"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      [
                        _c(
                          "h3",
                          {
                            staticClass: "mb-0 text-black",
                            staticStyle: { "font-size": "16px" },
                          },
                          [_vm._v(" Treatment Plans ")]
                        ),
                        _c(
                          "b-list-group",
                          { attrs: { flush: "" } },
                          [
                            _vm._l(
                              _vm.patientInfo.treamentplandetail,
                              function (treat, a) {
                                return _c(
                                  "b-list-group-item",
                                  { key: a },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "mb-2",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _c("b-col", [
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-black",
                                                      staticStyle: {
                                                        "font-size": "14px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          treat.description
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm.patientInfo.treamentplandetail != undefined &&
                            _vm.patientInfo.treamentplandetail.length == 0
                              ? _c("b-list-group-item", [
                                  _c("p", { staticClass: "text-muted mb-0" }, [
                                    _vm._v("No Medication"),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-col",
              { staticClass: "mb-3", attrs: { cols: "12" } },
              [
                _c(
                  "b-row",
                  { attrs: { "align-v": "start" } },
                  [
                    _c("b-col", { attrs: { cols: "12" } }, [
                      _vm.isEditing
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "mb-3" },
                              [
                                _c("p", { staticClass: "text-muted mb-2" }, [
                                  _c("small", [_vm._v("Address Line 1")]),
                                ]),
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.addressline1,
                                    callback: function ($$v) {
                                      _vm.addressline1 = $$v
                                    },
                                    expression: "addressline1",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mb-3" },
                              [
                                _c("p", { staticClass: "text-muted mb-2" }, [
                                  _c("small", [_vm._v("Address Line 2")]),
                                ]),
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.addressline2,
                                    callback: function ($$v) {
                                      _vm.addressline2 = $$v
                                    },
                                    expression: "addressline2",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mb-3" },
                              [
                                _c("p", { staticClass: "text-muted mb-2" }, [
                                  _c("small", [_vm._v("City")]),
                                ]),
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.city,
                                    callback: function ($$v) {
                                      _vm.city = $$v
                                    },
                                    expression: "city",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("p", { staticClass: "text-muted mb-2" }, [
                                  _c("small", [_vm._v("Zip Code")]),
                                ]),
                                _c("b-form-input", {
                                  model: {
                                    value: _vm.zipcode,
                                    callback: function ($$v) {
                                      _vm.zipcode = $$v
                                    },
                                    expression: "zipcode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _c("div", [
                            _c("p", { staticClass: "text-muted mb-2" }, [
                              _c("small", [_vm._v("Mailing Address")]),
                            ]),
                            _vm.patientInfo.defaultaddress
                              ? _c("h4", { staticClass: "text-black" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${
                                          _vm.patientInfo.defaultaddress
                                            .address1
                                        } ${
                                          _vm.patientInfo.defaultaddress
                                            .address2 || ""
                                        } ${
                                          _vm.patientInfo.defaultaddress.city
                                        } ${
                                          _vm.patientInfo.defaultaddress.zipcode
                                        }` || ""
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("b-col", { staticClass: "mb-3", attrs: { cols: "12" } }, [
              _c("p", { staticClass: "text-muted mb-2" }, [
                _c("small", [_vm._v("Patient Notes")]),
              ]),
              _c("h4", { staticClass: "text-black" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.patientInfo.patientnotes || "No Information Available"
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("b-col", { staticClass: "mb-3", attrs: { cols: "12" } }, [
              _c("p", { staticClass: "text-muted mb-2" }, [
                _c("small", [_vm._v("Price Version")]),
              ]),
              _c(
                "a",
                {
                  staticClass: "text-secondary tw-cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:showPricing", true)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.patientInfo.pricingversion === "v2"
                          ? "Version 2"
                          : "Version 1"
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]
        : [
            _c("b-col", { staticClass: "mb-3", attrs: { cols: "6" } }, [
              _c("p", { staticClass: "text-muted mb-2" }, [
                _c("small", [_vm._v("Date of Birth")]),
              ]),
              _c("h4", { staticClass: "text-black" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm
                        .moment(_vm.patientInfo.birthday)
                        .format("MMMM D, YYYY") || "No Information Available"
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("b-col", { attrs: { cols: "3" } }, [
              _c("p", { staticClass: "text-muted mb-2" }, [
                _c("small", [_vm._v("Age")]),
              ]),
              _c("h4", { staticClass: "text-black" }, [
                _vm._v(_vm._s(_vm.patientInfo.age)),
              ]),
            ]),
            _c(
              "b-col",
              {
                style: { "padding-left": _vm.isEditing ? "0" : "12px" },
                attrs: { cols: "3" },
              },
              [
                _c("p", { staticClass: "text-muted mb-2" }, [
                  _c("small", [_vm._v("State")]),
                ]),
                _c("h4", { staticClass: "text-black" }, [
                  _vm._v(_vm._s(_vm.patientInfo.state)),
                ]),
              ]
            ),
            _c("b-col", { staticClass: "mb-3", attrs: { cols: "12" } }, [
              _c("p", { staticClass: "text-muted mb-2" }, [
                _c("small", [_vm._v("Address")]),
              ]),
              _c("h4", { staticClass: "text-black" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      `${_vm.patientInfo.defaultaddress.address1} ${
                        _vm.patientInfo.defaultaddress.address2 || ""
                      } ${_vm.patientInfo.defaultaddress.city} ${
                        _vm.patientInfo.defaultaddress.zipcode
                      }` || "No Information Available"
                    ) +
                    " "
                ),
              ]),
            ]),
            _vm.patientInfo.defaultaddress &&
            _vm.patientInfo.defaultaddress.timezone
              ? _c("b-col", { staticClass: "mb-3", attrs: { cols: "12" } }, [
                  _c("p", { staticClass: "text-muted mb-2" }, [
                    _c("small", [_vm._v("Timezone")]),
                  ]),
                  _c("h4", { staticClass: "text-black" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.patientInfo.defaultaddress.timezone ||
                            "No Information Available"
                        ) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("b-col", { staticClass: "mb-3", attrs: { cols: "12" } }, [
              _c("p", { staticClass: "text-muted mb-2" }, [
                _c("small", [_vm._v("Email Address")]),
              ]),
              _c("h4", { staticClass: "text-black" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.patientInfo.email || "No Information Available"
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "b-col",
              { staticClass: "mb-3", attrs: { cols: "12" } },
              [
                _c("p", { staticClass: "text-muted mb-2" }, [
                  _c("small", [_vm._v("Contact Number")]),
                ]),
                _vm.isEditing
                  ? _c("b-form-input", {
                      attrs: { formatter: _vm.formatPhoneNumber },
                      model: {
                        value: _vm.contactNumber,
                        callback: function ($$v) {
                          _vm.contactNumber = $$v
                        },
                        expression: "contactNumber",
                      },
                    })
                  : _c("h4", { staticClass: "text-black" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.patientInfo.phoneno ||
                              "No Information Available"
                          ) +
                          " "
                      ),
                    ]),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }