<template>
  <b-row>
    <b-col cols="12" class="mb-4">
      <b-row align-v="center">
        <b-col cols="auto">
          <b-avatar size="85px" :src="patientInfo.profileUrl"></b-avatar>
        </b-col>
        <b-col>
          <div v-if="isEditing" class="mb-4">
            <div>
              <p class="text-muted mb-2">
                <small>First Name</small>
              </p>
              <b-form-input v-model="firstname"></b-form-input>
            </div>
            <div>
              <p class="text-muted mb-2">
                <small>Last Name</small>
              </p>
              <b-form-input v-model="lastname"></b-form-input>
            </div>
          </div>
          <h3 v-else class="bold">
            {{
              `${patientInfo.firstname} ${patientInfo.lastname}` || "Unnamed"
            }}
          </h3>
          <b-button
            pill
            size="sm"
            variant="outline-dark"
            block
            class="d-flex align-items-center justify-content-center"
            @click="$emit('update:showNotes', true)"
          >
            <v-icon name="note" variant="" size="20" color="currentColor" />
            <span class="d-inline-block ml-2"> Notes </span>
            <div
              class="tw-text-sm tw-ml-1"
              v-if="patientInfo.medicalnotescount > 0"
            >
              <b-badge class="tw-text-white" pill>
                {{ patientInfo.medicalnotescount }}
              </b-badge>
            </div>
          </b-button>
          <b-button
            v-if="!currentDoctor.id"
            pill
            size="sm"
            variant="outline-dark"
            block
            class="d-flex align-items-center justify-content-center"
            @click="$bvModal.show('setReminder')"
          >
            <v-icon name="reminder" color="currentColor" variant="" />
            <span>Set Reminder</span>
          </b-button>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" class="mb-4">
      <a
        v-if="!isViewingContactInfo"
        class="
          tw-uppercase
          hover:tw-underline
          tw-text-xs tw-tracking-wider tw-cursor-pointer
        "
        @click="isViewingContactInfo = true"
      >
        Contact Info & Address
      </a>
      <a
        v-else
        class="
          tw-uppercase
          hover:tw-underline
          tw-text-xs
          tw-tracking-wider
          tw-cursor-pointer
          tw-inline-flex
          tw-items-center
        "
        @click="isViewingContactInfo = false"
      >
        <svg
          width="16px"
          height="8px"
          viewBox="0 0 16 8"
          style="
            fill-rule: evenodd;
            clip-rule: evenodd;
            stroke-linejoin: round;
            stroke-miterlimit: 2;
          "
        >
          <g transform="matrix(1,0,0,1,-1232.66,-1750.33)">
            <path
              d="M1232.66,1753.77L1232.66,1753.77L1232.66,1754.97C1234.55,1754.97 1235.52,1756.6 1235.52,1757.54L1236.72,1757.54C1236.72,1756.72 1236.23,1755.51 1235.24,1754.68L1247.66,1754.68L1247.66,1753.48L1235.11,1753.48C1236.18,1752.57 1236.72,1751.18 1236.72,1750.33L1235.52,1750.33C1235.52,1751.29 1234.57,1753.19 1232.66,1753.19L1232.66,1753.77Z"
              style="fill: rgb(255, 141, 64)"
            />
          </g>
        </svg>
        <span class="tw-inline-block ml-1 tw-text-inherit">Back</span>
      </a>
    </b-col>
    <template v-if="!isViewingContactInfo">
      <b-col cols="12" class="mb-3">
        <b-row align-v="start">
          <b-col cols="6" class="mb-3">
            <p class="text-muted mb-2">
              <small>Date of Birth</small>
            </p>
            <b-form-datepicker
              v-if="isEditing"
              id="birthdate"
              :date-format-options="{
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              }"
              :hide-header="true"
              v-model="birthday"
              :dark="isDarkMode"
            >
              <template #button-content> </template>
            </b-form-datepicker>
            <h4 v-else class="text-black">
              {{
                moment(patientInfo.birthday).format("MMMM D, YYYY") ||
                "No Information Available"
              }}
            </h4>
          </b-col>
          <b-col cols="3">
            <p class="text-muted mb-2">
              <small>Age</small>
            </p>
            <h4 class="text-black">{{ patientInfo.age }}</h4>
          </b-col>
          <b-col cols="3" :style="{ 'padding-left': isEditing ? '0' : '12px' }">
            <p class="text-muted mb-2">
              <small>State</small>
            </p>
            <h4 class="text-black">{{ patientInfo.state }}</h4>
          </b-col>
          <b-col cols="12">
            <template
              v-if="
                patientInfo.unselectedproduct != undefined &&
                patientInfo.unselectedproduct.length > 0
              "
            >
              <p class="text-muted mb-2">
                <small>Declined onboarding treatment</small>
              </p>
              <h4
                class="text-black"
                v-for="(plan, i) in patientInfo.unselectedproduct"
                :key="i"
              >
                {{ plan.name || "" }}
              </h4>
            </template>
          </b-col>

          <b-col v-if="patientInfo.followupdatestamp" cols="12" class="mb-3">
            <p class="text-muted mb-2">
              <small>Follow-up Date</small>
            </p>
            <h4 class="text-black">
              {{ parseDate(patientInfo.followupdatestamp, "MMMM d, yyyy") }}
            </h4>
          </b-col>
          <b-col cols="12" class="mb-3">
            <p class="text-muted mb-2">
              <small>Treatment requested</small>
            </p>
            <template v-if="patientInfo.desiredplan">
              <h4
                class="text-black"
                v-for="(plan, i) in patientInfo.desiredplan"
                :key="i"
              >
                {{ plan.name || "" }}
              </h4>
            </template>
            <template v-else-if="patientInfo.preferredtreatment">
              <h4 class="text-black">
                {{ patientInfo.preferredtreatment || "" }}
              </h4>
            </template>
            <template v-else>
              <h4 class="text-black">Let doctor choose medication</h4>
            </template>
          </b-col>
          <b-col v-if="patientInfo.upsell.length > 0" cols="12" class="mb-3">
            <p class="text-muted mb-2">
              <small>Prescription Request</small>
            </p>
            <h4
              v-for="(upsell, i) in patientInfo.upsell"
              :key="i"
              class="text-black"
            >
              {{ upsell.name }}
              <span v-if="upsell.subscriptiontype">
                ({{ upsell.subscriptiontype }})
              </span>
            </h4>
          </b-col>
          <b-col
            v-if="patientInfo.preferredshipmentname"
            cols="12"
            class="mb-3"
          >
            <p class="text-muted mb-2">
              <small>Preferred Shipment</small>
            </p>
            <h4 class="text-black">
              {{
                patientInfo.preferredshipmentname || "No Information Available"
              }}
            </h4>
          </b-col>

          <!-- Treatment Plan -->
          <b-col>
            <h3 class="mb-0 text-black" style="font-size: 16px">
              Treatment Plans
            </h3>
            <b-list-group flush>
              <b-list-group-item
                v-for="(treat, a) in patientInfo.treamentplandetail"
                :key="a"
              >
                <b-row>
                  <b-col cols="12" class="mb-2">
                    <b-row>
                      <b-col>
                        <small class="text-black" style="font-size: 14px">{{
                          treat.description
                        }}</small>
                      </b-col>
                      <!-- <b-col cols="auto">
                  <small>{{
                    moment.unix(treat.date).format("MM/DD/YY")
                  }}</small>
                </b-col> -->
                    </b-row>
                    <!-- <b-row>
                <b-col>
                  <b-badge
                    style="
                      padding: 5px;
                      border-radius: 9999px;
                      display: inline-block;
                    "
                    :class="[
                      'mr-2',
                      treat.status == 'PAUSED'
                        ? 'badge-warning'
                        : treat.status == 'CANCELLED'
                        ? 'badge-danger'
                        : treat.status == 'ACCEPTED'
                        ? 'badge-success'
                        : treat.status == 'PRESCRIBED'
                        ? 'badge-success'
                        : '',
                    ]"
                  ></b-badge>
                  <small>{{ treat.status }}</small>
                </b-col>
              </b-row> -->
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item
                v-if="
                  patientInfo.treamentplandetail != undefined &&
                  patientInfo.treamentplandetail.length == 0
                "
              >
                <p class="text-muted mb-0">No Medication</p>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="mb-3">
        <b-row align-v="start">
          <!-- <b-col cols="12" class="mb-3">
            <p class="text-muted mb-2">
              <small>Gender</small>
            </p>
            <h4 class="text-black">Biological Female</h4>
          </b-col> -->
          <b-col cols="12">
            <div v-if="isEditing">
              <div class="mb-3">
                <p class="text-muted mb-2">
                  <small>Address Line 1</small>
                </p>
                <b-form-input v-model="addressline1"></b-form-input>
              </div>
              <div class="mb-3">
                <p class="text-muted mb-2">
                  <small>Address Line 2</small>
                </p>
                <b-form-input v-model="addressline2"></b-form-input>
              </div>
              <div class="mb-3">
                <p class="text-muted mb-2">
                  <small>City</small>
                </p>
                <b-form-input v-model="city"></b-form-input>
              </div>
              <div>
                <p class="text-muted mb-2">
                  <small>Zip Code</small>
                </p>
                <b-form-input v-model="zipcode"></b-form-input>
              </div>
            </div>
            <div v-else>
              <p class="text-muted mb-2">
                <small>Mailing Address</small>
              </p>
              <h4 class="text-black" v-if="patientInfo.defaultaddress">
                {{
                  `${patientInfo.defaultaddress.address1} ${
                    patientInfo.defaultaddress.address2 || ""
                  } ${patientInfo.defaultaddress.city} ${
                    patientInfo.defaultaddress.zipcode
                  }` || ""
                }}
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p class="text-muted mb-2">
          <small>Patient Notes</small>
        </p>
        <h4 class="text-black">
          {{ patientInfo.patientnotes || "No Information Available" }}
        </h4>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p class="text-muted mb-2">
          <small>Price Version</small>
        </p>
        <a
          class="text-secondary tw-cursor-pointer"
          @click="$emit('update:showPricing', true)"
        >
          {{ patientInfo.pricingversion === "v2" ? "Version 2" : "Version 1" }}
        </a>
      </b-col>
    </template>
    <template v-else>
      <b-col cols="6" class="mb-3">
        <p class="text-muted mb-2">
          <small>Date of Birth</small>
        </p>
        <!-- <b-form-datepicker
              v-if="isEditing"
              id="birthdate"
              :date-format-options="{
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              }"
              :hide-header="true"
              v-model="birthday"
              :dark="isDarkMode"
            >
              <template #button-content> </template>
            </b-form-datepicker> -->
        <h4 class="text-black">
          {{
            moment(patientInfo.birthday).format("MMMM D, YYYY") ||
            "No Information Available"
          }}
        </h4>
      </b-col>
      <b-col cols="3">
        <p class="text-muted mb-2">
          <small>Age</small>
        </p>
        <h4 class="text-black">{{ patientInfo.age }}</h4>
      </b-col>
      <b-col cols="3" :style="{ 'padding-left': isEditing ? '0' : '12px' }">
        <p class="text-muted mb-2">
          <small>State</small>
        </p>
        <h4 class="text-black">{{ patientInfo.state }}</h4>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p class="text-muted mb-2">
          <small>Address</small>
        </p>
        <h4 class="text-black">
          {{
            `${patientInfo.defaultaddress.address1} ${
              patientInfo.defaultaddress.address2 || ""
            } ${patientInfo.defaultaddress.city} ${
              patientInfo.defaultaddress.zipcode
            }` || "No Information Available"
          }}
        </h4>
      </b-col>
      <b-col
        cols="12"
        class="mb-3"
        v-if="patientInfo.defaultaddress && patientInfo.defaultaddress.timezone"
      >
        <p class="text-muted mb-2">
          <small>Timezone</small>
        </p>
        <h4 class="text-black">
          {{
            patientInfo.defaultaddress.timezone || "No Information Available"
          }}
        </h4>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p class="text-muted mb-2">
          <small>Email Address</small>
        </p>
        <h4 class="text-black">
          {{ patientInfo.email || "No Information Available" }}
        </h4>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p class="text-muted mb-2">
          <small>Contact Number</small>
        </p>
        <b-form-input
          v-if="isEditing"
          v-model="contactNumber"
          :formatter="formatPhoneNumber"
        ></b-form-input>
        <h4 v-else class="text-black">
          {{ patientInfo.phoneno || "No Information Available" }}
        </h4>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import { OnboardingService } from "@/services/onboarding.service";
import { format, fromUnixTime } from "date-fns";
import popper from "@/assets/js/popper.js";
export default {
  mixins: [popper],
  props: {
    patientInfo: {
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isViewingContactInfo: false,

      firstname: "",
      lastname: "",
      birthday: "",
      addressline1: "",
      addressline2: "",
      city: "",
      zipcode: "",
      contactNumber: "",
    };
  },
  computed: {
    isDarkMode() {
      return this.$store.state.globals.isDarkMode;
    },
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
  },
  async created() {
    this.$watch(
      (vm) => ({
        firstname: vm.firstname,
        lastname: vm.lastname,
        birthday: vm.birthday,
        phoneno: vm.contactNumber,
        shippingaddress: {
          address1: vm.addressline1,
          address2: vm.addressline2,
          zipcode: vm.zipcode,
          city: vm.city,
        },
      }),
      (val) => {
        this.$emit("update:values", val);
      },
      {
        immediate: true,
      }
    );

    this.firstname = this.patientInfo.firstname;
    this.lastname = this.patientInfo.lastname;
    this.birthday = this.patientInfo.birthday;
    this.addressline1 = this.patientInfo.billingaddress.address1;
    this.addressline2 = this.patientInfo.address2;
    this.city = this.patientInfo.billingaddress.city;
    this.zipcode = this.patientInfo.billingaddress.zipcode;
    this.email = this.patientInfo.email;
    if (/(\+1)/.test(this.patientInfo.phoneno)) {
      this.contactNumber = this.patientInfo.phoneno.replace(/(\+1)/, "");
    } else {
      this.contactNumber = this.patientInfo.phoneno;
    }
  },
  methods: {
    parseDate(date, stringFormat) {
      return format(fromUnixTime(date), stringFormat);
    },
    formatPhoneNumber(number) {
      if (!number) return;
      // clears dashes first
      let strippedNumber = number.replace(/-/g, "").replace(/[^\d.-]/g, "");
      let areacode = strippedNumber.slice(0, 3);
      let first3 = strippedNumber.slice(3, 6);
      let last4 = strippedNumber.slice(6);
      let formatted = strippedNumber;
      if (strippedNumber.length > 3) {
        formatted = `${areacode}`;
        if (strippedNumber.length < 7) {
          formatted = `${areacode}-${first3}`;
        }
        if (strippedNumber.length >= 7) {
          formatted = `${areacode}-${first3}-${last4}`;
        }
      }
      return formatted;
    },
  },
};
</script>

<style scoped>
/deep/input.form-control,
/deep/.gender .vs__dropdown-toggle,
/deep/.state .vs__dropdown-toggle {
  padding: 0;
  height: auto;
  font-size: 15px;
  margin-bottom: 9px;
  line-height: 0;
  letter-spacing: -0.02em;
  border-color: rgba(79, 79, 79, 1);
}

/deep/div.form-control {
  border-color: rgba(79, 79, 79, 1);
}

/deep/label.form-control {
  padding: 0 !important;
  min-height: auto !important;
  font-size: 15px;
  margin-bottom: 9px;
  letter-spacing: -0.02em;
}

/deep/#textarea.form-control {
  line-height: 1.5;
}

/deep/#birthdate.btn {
  padding: 0;
}
</style>
